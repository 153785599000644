import { Injectable } from '@angular/core';
//import { FaroSearchResponse } from '@faro/searchapi-angular-client';
import { SearchFieldSelectionEnum } from '../../search/search-state/search-options.state';
import { Store } from '@ngrx/store';
import { AddShoppingCartEntryRequestDto, AudioTrack } from '@faro/order-angular-client';
import { HeaderComponent } from 'src/app/core/header/header.component';
import { ActionProviderService } from './action-provider.service';
import { resetAllFilters, resetSearch, triggerResetFacetSearch } from 'src/app/search/search-state/search-options.actions';
import { dispatchEmptySearch } from 'src/app/search/search-state/search-result.actions';
import { Router } from '@angular/router';
//import { addToDocSet, addToShoppingCart } from '../../search/search-state/search-result.actions';

@Injectable()
export class HostConnectorService {

    private readonly _webView: any = undefined;

    constructor(private readonly store: Store,
                private readonly router: Router
    ) {
        // are we running in chromium webview container host?
        const chrome = (window as any).chrome;
        if (chrome) {
            this._webView = chrome.webview;
        }
        if (this._webView) {
            this._webView.addEventListener('message', (e: MessageEvent) => this.onMessage(e));
        }
    }

    public isAppInWebViewContainer(): boolean {
        return !!this._webView;
    }

    public get webView() {
        return this._webView;
    }

    private onMessage(event: MessageEvent): void {
        if (event.data === "goHome") {
            this.goHome();
        }
    }

    async goHome() {
        this.store.dispatch(triggerResetFacetSearch());
        this.store.dispatch(resetSearch());
        this.store.dispatch(resetAllFilters());
        this.store.dispatch(dispatchEmptySearch());
        return this.router.navigate(['/']);
    }

    private emitMessage(message: HostMessage): void {
        if (this._webView) {
            this._webView.postMessage(message);
        }
    }

    emitAddShoppingCartEntryMessage(item: AddShoppingCartEntryRequestDto): void {
        let id = item.sequenceId ? item.sequenceId : (item.itemId === null ? undefined : item.itemId);
        let entityType = item.sequenceId ? EntityType.Shot : EntityType.Item;
        if (id) {
            const message: EntityMessage = {
                messageType: 'EntityMessage',
                action: Action.ShoppingCart,
                type: entityType,
                id,
                remark: item.remark ?? undefined,
                audioTrack: item.selectedAudioTrack,
                mediaCutId: item.mediaCutId ?? undefined,
                vtcIn: item.vtcIn ?? undefined,
                vtcOut: item.vtcOut ?? undefined

            };
            this.emitMessage(message);

            // if state must be tracked, e.g. for desactivate add buttons, use this:
            //    if (action === Action.DocSet) {
            //        this.store.dispatch(addToDocSet({ id }));
            //    }
            //    if (action === Action.ShoppingCart) {
            //        this.store.dispatch(addToShoppingCart({ id }));
            //    }
        }
    }

    emitAction(action: Action): void {
        const message: HostMessage = {
            messageType: 'HostMessage',
            action: action,
        };
        this.emitMessage(message);
    }

    // emitActionLogMessage(response: FaroSearchResponse, elapsedMs: number) {
    //     const message: ActionLogMessage = {
    //         messageType: 'ActionLogMessage',
    //         action: Action.ActionLog,
    //         request: JSON.stringify(response.request),
    //         searchType: response.request.searchType || '',
    //         hits: response.hitList.totalHitCount,
    //         elapsedMs: elapsedMs,
    //     };
    //     this.emitMessage(message);
    // }
}

interface HostMessage {
    messageType: string;
    action: Action;
}

interface EntityMessage extends HostMessage {
    type: EntityType;
    id: string;
    remark?: string;
    audioTrack?: AudioTrack;
    mediaCutId?: string;
    vtcIn?: string;
    vtcOut?: string;
}

interface ActionLogMessage extends HostMessage {
    request: string;
    searchType: string;
    hits: number;
    elapsedMs: number;
}

export enum Action {
    Detail = 'Detail',
    DocSet = 'DocSet',
    ShoppingCart = 'ShoppingCart',
    AuthToken = 'AuthToken',
    ActionLog = 'ActionLog',
}

export enum EntityType {
    Shot = 'Shot',
    Item = 'Item',
}