export const environment = {
    production: true,
    oidcAuthority: '/api/auth',
    searchApiBaseUrl: 'api/search',
    hlsProxyBaseUrl: 'hlsproxy',
    metadataApiBaseUrl: 'api/metadata',
    profileApiBaseUrl: 'api/profile',
    orderApiBaseUrl: 'api/order',
    appVersion: "1.1.0-develop-250",
};
