import { Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, filter, map, takeUntil, take, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    selectAudioLabel,
    selectDetailAvComponent,
    selectDetailPlayerMediaCut,
    selectDetailMediaSelection,
    selectDetailNav,
    selectInOutSelectionChanged,
    selectIsInOutSelectionValid,
    selectSequenceInformation,
    selectDetailEverythingMedia,
    selectProgramItemInformation,
    selectProgramSequenceInformation,
    selectDetailOrderVtcIn,
    selectDetailOrderVtcOut,
    selectPreviousPlayerState,
} from '../details-state/details.selectors';
import {
    getDetailItemSequence,
    getDetailProgramSequence,
    notifyOfDownloadError,
    setPlayerMediaCut,
    setMediaSelection,
    setPreviousPlayerState,
} from '../details-state/details.actions';
import { PlayerComponent } from '../../player/player/player.component';
import { TimeService } from '../shared/services/time.service';
import { MediaInformation } from '../../player/player/media-information.interface';
import { LightTableViewComponent } from '../light-table-view/light-table-view.component';
import { ActionLogService, AudioTrack, ReportService } from '@faro/metadata-angular-client';
import { HttpErrorResponse } from '@angular/common/http';
import { downloadBlob } from '../../shared/download-blob';
import { Duration } from '../../shared/duration';
import { MediaCutInfo } from '../../player/shared/media-cut-info';
import { NavigationMetadataDto } from '@faro/metadata-angular-client/model/navigationMetadataDto';
import { StoreUtilsService } from '../../state/store-utils.service';
import {
    OrderRemarkDialogContentAndConfig,
    OrderRemarkResponse,
} from '../../shared/dialogs/order-remark-dialog/order-remark-dialog-content-and.config';
import { ConfirmationDialogService } from '../../shared/services/confirmation-dialog.service';
import { OrderParamService } from '../shared/services/order-param.service';
import { audioTrackDescription, mediaCutDescription } from '../details-state/media-cut.helpers';
import { AddShoppingCartEntryRequestDto } from '@faro/order-angular-client';
import { formatTimeSpan } from '../../shared/timespan';
import { Router } from '@angular/router';
import { focusElement } from '../../shared/focus-element.helper';
import { convertQueryToKeywords } from '../shared/query-to-keyword';
import { selectQuery } from '../../search/search-state/search-options.selectors';
import { setCurrentHitId } from '../../state/shared.actions';
import { ActionProviderService } from 'src/app/shared/services/action-provider.service';

@Component({
    selector: 'app-detail-content',
    templateUrl: './detail-content.component.html',
    styleUrls: ['./detail-content.component.scss'],
})
export class DetailContentComponent implements OnInit, OnDestroy {
    @Input()
    readonly: boolean = false;

    readonly avComponent$: Observable<{ program: string; item: string | undefined; type: 'program' | 'item' } | null>;
    readonly nav$: Observable<NavigationMetadataDto | null>;
    readonly playerMediaCut$: Observable<MediaCutInfo | null>;
    readonly mediaInformation$: Observable<MediaInformation | null>;
    readonly sectionInformation$: Observable<{ vtcIn: Duration; vtcOut: Duration }[]>;
    readonly programSectionInformation$: Observable<{ vtcIn: Duration; vtcOut: Duration }[] | undefined>;
    readonly programSequenceSectionInformation$: Observable<{ vtcIn: Duration; vtcOut: Duration }[] | undefined>;

    splitterConfig = splitterConfiguration;
    verticalSplitterConfig = verticalSplitterConfiguration;
    isInOutSelectionValid$: Observable<boolean>;

    keywords: string[] = [];

    hideButtonText = false;

    vtcIn$: Observable<Duration | undefined> = of(undefined);

    vtcOut$: Observable<Duration | undefined> = of(undefined);

    previousPlayerState$: Observable<boolean>;

    resizeObserver = new ResizeObserver(entries => {
        const inOut = entries[0];
        if (inOut) {
            const inOutWidth = Math.floor(inOut.contentRect.width);
            this.hideButtonText = inOutWidth < 610;
        }
    });

    private _destroyed$ = new Subject<void>();

    @ViewChild('flowPlayer')
    set flowPlayer(fp: PlayerComponent) {
        if (fp) {
            this.timeService.setProvider(fp);
        } else {
            this.timeService.clearProvider();
        }
    }

    @ViewChild('lightTableView', { static: false })
    lightTableView: LightTableViewComponent | undefined;

    @ViewChild('inOutElement') inOutElement: ElementRef | undefined;

    constructor(
        private store: Store,
        private timeService: TimeService,
        private reportService: ReportService,
        private actionLogService: ActionLogService,
        private router: Router,
        private readonly storeUtils: StoreUtilsService,
        private readonly orderParamService: OrderParamService,
        private readonly confirmationDialogService: ConfirmationDialogService,
        private readonly actionProviderService: ActionProviderService,
        private zone: NgZone
    ) {
        this.avComponent$ = store.select(selectDetailAvComponent).pipe(map(x => x || null));
        this.nav$ = store.select(selectDetailNav).pipe(map(x => x || null));

        this.playerMediaCut$ = store.select(selectDetailPlayerMediaCut).pipe(map(x => x || null));

        this.sectionInformation$ = store.select(selectSequenceInformation);
        this.programSectionInformation$ = store.select(selectProgramItemInformation);
        this.programSequenceSectionInformation$ = store.select(selectProgramSequenceInformation);
        this.previousPlayerState$ = store.select(selectPreviousPlayerState);

        this.mediaInformation$ = store.select(selectDetailEverythingMedia).pipe(
            map(x => {
                if (!x.component || !x.mediaSelection.mediaCut || !x.mediaCuts) {
                    return null;
                }

                const component = x.component!;
                const selectedMediaCut = x.mediaCuts!.find(mc => mc.id === x.mediaSelection.mediaCut);
                const selectedAudioTrack = selectedMediaCut?.audioTracks.find(
                    at => at.id == x.mediaSelection.audioTrack
                );
                return selectedMediaCut && selectedAudioTrack
                    ? {
                          program: component.program,
                          item: component.type === 'item' ? component.item : undefined,
                          isAudioProgram: x.isAudioProgram,
                          selectedMediaCutId: selectedMediaCut.id,
                          selectedAudioTrackId: selectedAudioTrack.id,
                          mediaCuts: x.mediaCuts!.map(mc => ({ id: mc.id, description: mediaCutDescription(mc) })),
                          pictureAspectRatio: selectedMediaCut.pictureAspectRatio,
                          audioTracks: selectedMediaCut.audioTracks.map(at => ({
                              id: at.id,
                              description: audioTrackDescription(at),
                          })),
                      }
                    : null;
            })
        );

        this.isInOutSelectionValid$ = this.store.select(selectIsInOutSelectionValid);
    }

    ngOnInit(): void {
        this.store
            .select(selectDetailAvComponent)
            .pipe(
                takeUntil(this._destroyed$),
                filter(x => x !== undefined)
            )
            .subscribe(x => {
                const { program, item, type } = x!;
                this.actionLogService
                    .actionLogLogWebDetailViewShown({
                        programId: program,
                        itemId: item,
                        detailViewType: type,
                    })
                    .subscribe({
                        next: _ => {},
                        error: err => console.error('Error adding action log entry WebDetailViewShown', err),
                    });

                if (type === 'item') {
                    this.store.dispatch(getDetailItemSequence({ programId: program, itemId: item! }));
                } else {
                    this.store.dispatch(getDetailProgramSequence({ programId: program }));
                }
            });
        this.store
            .select(selectQuery)
            .pipe(take(1))
            .subscribe(query => {
                this.keywords = convertQueryToKeywords(query);
            });

        this.vtcIn$ = this.store.select(selectDetailOrderVtcIn);
        this.vtcOut$ = this.store.select(selectDetailOrderVtcOut);
    }

    checkResize() {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                if (this.inOutElement) {
                    this.resizeObserver.observe(this.inOutElement.nativeElement);
                }
            }, 500);
        });
    }

    ngOnDestroy(): void {
        this.timeService.clearProvider();
        this.resizeObserver.disconnect();
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    onMediaCutLoaded($event: MediaCutInfo | null) {
        if (this.inOutElement) {
            this.resizeObserver.unobserve(this.inOutElement.nativeElement);
        }
        this.store.dispatch(setPlayerMediaCut({ mediaCut: $event || undefined }));
        this.checkResize();
    }

    selectMedia(mediaCut: string) {
        this.store.dispatch(setMediaSelection({ mediaCut }));
    }

    setSelectedAudioTrack(audioTrack: string) {
        this.store.dispatch(setMediaSelection({ audioTrack: audioTrack as AudioTrack }));
    }

    onPlayingStarted() {
        this.lightTableView?.onPlayingStarted();
    }

    updatePlayState(event: boolean) {
        this.store.dispatch(setPreviousPlayerState({ playerState: event }));
    }

    printReport(navigationInfo: NavigationMetadataDto | null, itemId: string | null) {
        if (navigationInfo && navigationInfo.programId && itemId) {
            this.reportService.reportGetItemDetailsReport(navigationInfo.programId, itemId).subscribe({
                next: (blob: Blob) => {
                    const filename = navigationInfo.programTitle
                        ? `FARO Report Beitragsdetails - ${navigationInfo.programTitle}.docx`
                        : 'FARO Report Beitragsdetails.docx';
                    downloadBlob(document, blob, filename);
                },
                error: (err: HttpErrorResponse) => {
                    this.store.dispatch(notifyOfDownloadError({ errorResponse: err }));
                },
            });
        }
    }

    async orderInOutSelection() {
        const selectedVtcInOutSelection = await this.orderParamService.getVtcInOutSelection();
        const selectionIds = await this.orderParamService.getSelectionIds();
        const inOutSelectionChanged = await this.storeUtils.snapshot(selectInOutSelectionChanged);
        const item: AddShoppingCartEntryRequestDto = {
            itemId: selectionIds.itemId,
            programId: selectionIds.programId,
            vtcIn: inOutSelectionChanged ? formatTimeSpan(selectedVtcInOutSelection.vtcIn) : undefined,
            vtcOut: inOutSelectionChanged ? formatTimeSpan(selectedVtcInOutSelection.vtcOut) : undefined,
            ignoreUserRestrictions: false,
            ignoreSizeLimit: false,
        };
        this.actionProviderService.getShoppingCartAction(item)();
    }

    async orderInOutSelectionWithRemark() {
        const selectedMedia = await this.storeUtils.snapshot(selectDetailMediaSelection);
        const audioLabel = await this.storeUtils.snapshot(selectAudioLabel);
        const selectionIds = await this.orderParamService.getSelectionIds();
        const vtcInOutSelection = await this.orderParamService.getVtcInOutSelection();
        const inOutSelectionChanged = await this.storeUtils.snapshot(selectInOutSelectionChanged);
        const dialogContentAndConfig: OrderRemarkDialogContentAndConfig = {
            audioTrack: audioLabel,
            displayCheckBox: true,
        };
        this.confirmationDialogService
            .openRemarkDialog(dialogContentAndConfig)
            .pipe(takeUntil(this._destroyed$))
            .subscribe((data: OrderRemarkResponse) => {
                if (data) {
                    const item: AddShoppingCartEntryRequestDto = {
                        itemId: selectionIds.itemId,
                        programId: selectionIds.programId,
                        vtcIn: inOutSelectionChanged ? formatTimeSpan(vtcInOutSelection.vtcIn) : undefined,
                        vtcOut: inOutSelectionChanged ? formatTimeSpan(vtcInOutSelection.vtcOut) : undefined,
                        selectedAudioTrack: data.orderAudioOnly ? selectedMedia.audioTrack : undefined,
                        mediaCutId: data.orderAudioOnly ? selectedMedia.mediaCut : undefined,
                        remark: data.orderRemark,
                        ignoreUserRestrictions: false,
                        ignoreSizeLimit: false,
                    };
                    this.actionProviderService.getShoppingCartAction(item)();
                }
                focusElement('order-with-remark-player-button');
            });
    }

    goToDetails(programId: string, itemId: string | undefined) {
        this.store.dispatch(setCurrentHitId({ hitId: undefined }));
        this.router.navigate(['details', programId, itemId ?? '']);
    }

    jumpToVtcIn(event: { vtcIn: Duration }) {
        this.timeService.jumpToVtc(event.vtcIn);
    }

    @HostListener('window:keyup', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const keyFromInputElement = (event.target as HTMLElement).tagName.toLowerCase()
            ? (event.target as HTMLElement).tagName.toLowerCase() === 'input'
            : false;
        if (!keyFromInputElement) {
            if (event.key === 'v') {
                this.orderInOutSelectionWithRemark().then();
            }
            if (event.key === 'w') {
                this.orderInOutSelection().then();
            }
        }
    }
}

const splitterConfiguration = {
    storageLocation: 'local',
    storageKey: 'splitter-size',
};

const verticalSplitterConfiguration = {
    storageLocation: 'local',
    storageKey: 'vertical-splitter-size',
};
