import { Injectable } from "@angular/core";
import { HostConnectorService } from "./host-connector.service";
import { AddShoppingCartEntryRequestDto } from "@faro/order-angular-client";
import { Store } from "@ngrx/store";
import { addItemToActiveShoppingCart } from "src/app/order/shopping-cart-state/shopping-cart.actions";
import { Router } from "@angular/router";
import { resetAllFilters, resetSearch, triggerResetFacetSearch } from "src/app/search/search-state/search-options.actions";
import { dispatchEmptySearch } from "src/app/search/search-state/search-result.actions";

@Injectable()
export class ActionProviderService {

    constructor(private readonly hostConnector: HostConnectorService,
        private readonly router: Router,
        private readonly store: Store
    ) {}

    getShoppingCartAction(item: AddShoppingCartEntryRequestDto): () => void {
        if (this.hostConnector.isAppInWebViewContainer()) {
            return () => this.hostConnector.emitAddShoppingCartEntryMessage(item)
        }

        return () => this.store.dispatch(addItemToActiveShoppingCart({ params: item }));
    }
}