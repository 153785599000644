import { Component, OnInit } from '@angular/core';
import { Observable, of, map, switchMap, timer, share, catchError } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HostConnectorService } from 'src/app/shared/services/host-connector.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    isUserAuthenticated$: Observable<boolean> = of(false);

    text$: Observable<string> = of('');

    pollTimeout = 60000; // Once per minute

    constructor(
        private authService: OidcSecurityService,
        private hostConnector: HostConnectorService,
        private http: HttpClient
    ) {}

    ngOnInit() {
        this.isUserAuthenticated$ = this.authService.isAuthenticated$.pipe(
            map(authResult => authResult.isAuthenticated)
        );
        this.startBannerTextPolling();
    }

    startBannerTextPolling() {
        this.text$ = timer(0, this.pollTimeout).pipe(
            switchMap(_ => this.getBannerText()),
            share()
        );
    }

    getBannerText(): Observable<string> {
        const url = `api/auth/api/status-text`;
        return this.http
            .get<string>(url, { headers: new HttpHeaders({ Accept: 'application/json' }) })
            .pipe(catchError(_ => of('')));
    }

    async goHome() {
        this.hostConnector.goHome();
    }
}
