import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { RouteHistoryService } from './shared/route-history/route-history.service';
import { filter, Subject, Subscription, take, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { getUserInformation } from './state/user.actions';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { selectUserId } from './state/user.selectors';
import { getActiveShoppingCart } from './order/shopping-cart-state/shopping-cart.actions';
import { dispatchEmptySearch } from './search/search-state/search-result.actions';
import { selectDisplaySavedSearch } from './search/search-state/search-result.selectors';
import { getSearchProfile } from './search/search-state/search-profile.actions';
import { HostConnectorService } from './shared/services/host-connector.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private _routeHistorySubscription: Subscription = Subscription.EMPTY;
    private _destroyed = new Subject<void>();

    constructor(
        private readonly primeConfig: PrimeNGConfig,
        private readonly http: HttpClient,
        private readonly routeHistory: RouteHistoryService,
        private readonly store: Store,
        private readonly authService: OidcSecurityService,
        private readonly hostConnectorService: HostConnectorService
    ) {}

    ngOnInit() {
        this.primeConfig.ripple = true;

        // set German translations for PrimeNG components
        // see http://primefaces.org/primeng/#/i18n
        this.http.get<Translation>('assets/i18n/primeng-de.json').subscribe({
            next: data => this.primeConfig.setTranslation(data),
            error: e => console.error(e),
        });
        this.authService.isAuthenticated$.pipe(takeUntil(this._destroyed)).subscribe(data => {
            if (data.isAuthenticated) {
                this.store.dispatch(getUserInformation());
            }
        });

        this._routeHistorySubscription = this.routeHistory.subscribeToRouteChanges();

        window.addEventListener('resize', setAppHeight);
        setAppHeight();

        // Store initialization
        this.loadActiveShoppingCart();
        this.clearSearch();
        this.loadSearchProfile();
    }

    loadActiveShoppingCart(): void {
        this.store
            .select(selectUserId)
            .pipe(
                filter(x => !!x),
                take(1)
            )
            .subscribe(_ => {
                this.store.dispatch(getActiveShoppingCart());
            });
    }

    clearSearch(): void {
        this.store
            .select(selectDisplaySavedSearch)
            .pipe(take(1))
            .subscribe(data => {
                if (!data) this.store.dispatch(dispatchEmptySearch());
            });
    }

    loadSearchProfile(): void {
        this.store
            .select(selectUserId)
            .pipe(
                filter(x => !!x),
                take(1)
            )
            .subscribe(_ => {
                this.store.dispatch(getSearchProfile());
            });
    }

    get isAppInWebViewContainer(): boolean {
        return this.hostConnectorService.isAppInWebViewContainer();
    }

    ngOnDestroy() {
        this._routeHistorySubscription.unsubscribe();
        this._destroyed.next();
        this._destroyed.complete();
        window.removeEventListener('resize', setAppHeight);
    }
}

function setAppHeight() {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
}
